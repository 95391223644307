<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  name: "bankReceipts",
  data() {
    return {
      table: {
        modal: "BankReceipt",
        path: "bankReceipts",
        headers: [
          {
            text: this.$i18n.t("Code"),
            value: "applicant_code",
          },
          {
            text: this.$i18n.t("Name"),
            value: "applicant_name",
            sortable: true,
          },
          {
            text: this.$i18n.t("Notes"),
            value: "notes",
            sortable: false,
          },
          {
            text: this.$i18n.t("Date"),
            value: "date",
            sortable: true,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
          },
        ],
        title: this.$i18n.t("Bank Receipts"),
        apiEndPoints: {
          list: "payments/all_bank_receipts",
        },
        permissions: {
          list: "list-payments",
          show: "show-payments",
        },
      },
    };
  },

  components: {
    DataTableComponent,
  },
};
</script>

<style></style>
